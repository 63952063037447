<template>
    <v-container fluid fill-height>
        <v-row align="center" justify="center">
            <v-col md="auto">
                <img
                    :src="require('../assets/ausindustry-diser-crcp-logo.svg')"
                    style="max-width:200px"
                    class="pb-3">
                <img
                    :src="require('../assets/crchbp-logo.svg')"
                    style="max-width:200px; padding-right:20px; padding-left: 20px">
            </v-col>
            <v-col md="6">
                <h1 class="display-2 font-weight-bold mb-3">
                    Welcome to B-QUAL Test Library
                </h1>
                <p class="subheading font-weight-regular" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
}
</script>
